import { ComponentType, useMemo } from 'react'
import type React from 'react'

export type FindChildResponse = React.ReactElement | undefined;

function findChildByComponent(
  children: React.ReactElement | React.ReactElement[],
  Component: ComponentType<any>
): FindChildResponse {
  if (!Array.isArray(children)) {
    children = [children]
  }

  return children.find((item) => {
    if (!item) {
      return false
    }

    if (typeof item.type === 'undefined') {
      return false
    }

    return item.type === Component
  })
}

function findChildByDisplayName(
  children: JSX.Element | JSX.Element[],
  displayName: string
) {
  if (!Array.isArray(children)) {
    children = [children]
  }

  return children.find((item) => {
    if (!item) {
      return false
    }

    if (typeof item.type === 'undefined') {
      return false
    }

    return item.type.displayName === displayName
  })
}

export default function useComponent(
  children: JSX.Element | JSX.Element[],
  { Component, displayName }: {Component ?: ComponentType<any>, displayName ?: string}
) {
  return useMemo(() => (Component && findChildByComponent(children, Component))
  || (displayName && findChildByDisplayName(children, displayName)), [
    children,
    Component,
    displayName
  ])
}
