import { useCallback, useMemo, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { apiPut, apiPost, apiDelete } from 'mp-structure/libs/api'
import { useToast } from 'mp-common/hooks/useToast'
import usePopup, { PopupReturnType } from 'mp-common/hooks/usePopup'
import get from 'lodash/get'

type Callbacks = {
  resetForm ?: () => void
}

export type FormOnSuccessCallback = (response: Record<string, any>, callbacks?: Callbacks) => void

type Props<T> = {
  initialForm ?: T,
  initialErrors ?: Record<string, any>,
  endpoint ? : string,
  primaryKey ?: string,
  onSuccess ?: FormOnSuccessCallback,
  beforeSubmit ?: (data: T) => T,
  onError ?: (error: Record<string, any>) => void,
  required ?: string []
}

type DeleteProps ={
  id: number,
  okMsg: string,
  next: (isSuccess: boolean) =>void,
  confirmTitle:string,
  confirmMessage:string,
  url ?:string,
  onOk ?: (callbacks ?: PopupReturnType) => void
}

const useForm = <T extends Record<string, any>>(props : Props<T>) => {
  const {
    initialForm,
    initialErrors,
    endpoint: endpointProp,
    primaryKey,
    onSuccess,
    beforeSubmit,
    onError,
    required
  } = props
  const { t } = useTranslation()
  const [form, setForm] = useState<T>((initialForm || {}) as any)
  const [errors, setErrors] = useState(initialErrors || {})
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { toastSuccess } = useToast()
  const { popWarning, popConfirm, popConfirmSubmitting, ...rest } = usePopup()
  const endpoint = useMemo(() => endpointProp, [endpointProp])

  const onChange = useCallback((k: keyof T | string, v) => {
    setErrors(prevState => ({ ...prevState, [k]: false }))
    setForm(prevState => ({ ...prevState, [k]: v }))
  }, [form])

  const onChangeMultiple = useCallback((data) => {
    if (data) {
      const _errors = {}
      const _form = {}
      Object.keys(data).forEach(x => {
        _errors[x] = false
        _form[x] = data[x]
      })
      setErrors(prevState => ({ ...prevState, ..._errors }))
      setForm(prevState => ({ ...prevState, ..._form }))
    }
  }, [form])

  const handleDelete = async ({ id, okMsg, next, confirmTitle, confirmMessage, url = '', onOk = undefined }: DeleteProps) => {
    if (confirmTitle || confirmMessage) {
      popConfirm({
        title: confirmTitle,
        message: confirmMessage,
        onOk: onOk ? () => onOk({ popConfirmSubmitting, popConfirm, popWarning, ...rest }) : (async () => {
          popConfirmSubmitting(true)
          const response = await apiDelete(`${(url || endpoint)}/${id}`)
          popConfirmSubmitting(false)
          popConfirm(false)
          if (response && response.error) {
            popWarning({ title: t('popup:error'), msg: response.error })
            if (next) {
              next(false)
            }
          } else {
            toastSuccess(okMsg)
            if (next) {
              next(true)
            }
          }
        })
      })
    }
  }

  const resetForm = useCallback(() => {
    if (initialForm) {
      setForm(initialForm)
    }
  }, [initialForm])

  const handleSubmit = async (customForm ?: any) : Promise<boolean> => {
    setSubmitting(true)
    let response = { }
    let submitForm = customForm ? { ...customForm } : { ...form }
    if (beforeSubmit) {
      submitForm = beforeSubmit(submitForm)
    }
    try {
      const primary = primaryKey ? form[primaryKey] : form?.id
      if (primary) {
        response = await apiPut(`${endpoint}/${primary}`, submitForm)
      } else {
        response = await apiPost(endpoint, submitForm)
      }
      if (get(response, 'errors')) {
        setErrors(get(response, 'errors', {}))
        return false
      }
      setErrors({})
      if (get(response, 'errors', {}) && onError) {
        onError(response || {})
        return false
      }
      if (onSuccess) {
        onSuccess(response || {}, { resetForm })
      }
      toastSuccess(t('toast:savedSuccessfully'))
    } catch (e) {
      if (get(e, 'response.data.errors')) {
        const errors = get(e, 'response.data.errors') || {}
        setErrors(errors)
      }
      return false
    } finally {
      setSubmitting(false)
    }
    return true
  }

  const resetErrors = useCallback(() => {
    const newErrors = {}
    Object.keys(errors).forEach((key) => {
      newErrors[key] = false
    })
    setErrors(newErrors)
  }, [JSON.stringify(errors)])

  const isSubmittable = useCallback(() => (
    required?.filter((x) => !form[x])?.length === 0
  ), [required, JSON.stringify(form)])

  return {
    onChange,
    onChangeMultiple,
    form,
    handleSubmit,
    handleDelete,
    setForm,
    errors,
    setErrors,
    resetErrors,
    submitting,
    submittable: isSubmittable(),
    resetForm
  }
}

export default useForm
