import { useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Modal from 'mp-common/components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { StateType } from 'mp-common/types/StateType'
import { dispatchConfirmActionModal } from 'mp-common/stores/modal/action'
import { Dispatch } from 'redux'
import { GHref } from 'mp-common/components/form/elements'

const ConfirmActionModal = () => {
  const { t } = useTranslation('document')
  const dispatch: Dispatch<any> = useDispatch()
  const modalProps = useSelector((state:StateType) => state.modal.confirmAction)
  const [submitting, setSubmitting] = useState(false)
  const { title, onSubmit } = modalProps || {}

  const handleClose = () => {
    dispatchConfirmActionModal(false, dispatch)
  }

  const submit = async () => {
    setSubmitting(true)
    await onSubmit()
    setSubmitting(false)
  }

  return (
    <Modal
      isOpen={!!modalProps}
      close={handleClose}
      extraClass="modal modal-400"
    >
      <div className="tf-modal-body w-100">
        <div className="mp-grid-2-col gap-16">
          <h2 className="bold column-2">{title}</h2>
          <GHref submitting={submitting} onClick={submit} className="tm-button-bordered color-brown">
            {t('common:yes')}
          </GHref>
          <GHref onClick={handleClose} className="tm-button-filled color-brown">
            {t('common:no')}
          </GHref>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmActionModal
