import { PopupType } from 'mp-common/types/PopupType'
import usePopupStore from 'mp-common/stores/usePopupStore'

export type PopupReturnType = {
  warning : string | boolean | PopupType,
  success : string | boolean | PopupType,
  confirm : string | boolean | PopupType,
  information : string | boolean | PopupType,
  popWarning : (msg :string | boolean | PopupType) => any,
  popSuccess : (msg : string | boolean | PopupType) => any,
  popConfirm : (data: PopupType| boolean | string) => any,
  popConfirmCustom : (data) => any,
  popConfirmSubmitting : (isConfirming : boolean) => any,
  popInformation: (data: PopupType| boolean | string) => any
}
const usePopup = () : PopupReturnType => {
  const [warning, success, confirm, show, information] = usePopupStore(
    (state: Record<string, any>) => [
      state.warning,
      state.success,
      state.confirm,
      state.show,
      state.information
    ]
  )

  const popWarning = (msg) => show('warning', msg)

  const popSuccess = (msg) => show('success', msg)

  const popConfirmSubmitting = (isSubmitting) => show('confirming', isSubmitting)

  const popConfirm = ({ title, message, onOk, submitting, ...rest }) => show('confirm', { title, message, onOk, submitting, ...rest })

  const popConfirmCustom = (data) => show('confirm', data)

  const popInformation = (data) => show('information', data)

  return {
    warning,
    popWarning,
    success,
    popSuccess,
    confirm,
    popConfirm,
    popConfirmCustom,
    popConfirmSubmitting,
    popInformation,
    information
  }
}

export default usePopup
