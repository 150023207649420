import { useDispatch, useSelector } from 'react-redux'
import { StateType } from 'mp-common/types/StateType'
import { dispatchOptions } from 'mp-common/stores/data/action'
import { useEffect, useMemo, useState } from 'react'
import { freightApiGet } from 'mp-structure/libs/freightApi'
import useTranslation from 'next-translate/useTranslation'
import { ShipmentType } from 'mp-truck/types/TruckType'

let fetchingTypes = false

export const DEFAULT_GOOD_TYPE = 'general_goods'
export const DEFAULT_TRUCK_TYPE = 'tautliner-curtainsider'

export const TEMP_GOOD_TYPE = '1d2a37cb-aaba-4e19-9a86-3d77fe7e8269'
export const REEFER_TRUCK_TYPE = '00d07e69-0b80-4b31-96cd-8f1a3ff392ad'

const useOptions = () => {
  const { t } = useTranslation('options')
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const options = useSelector((state: StateType) => state.data.options)
  const setOptions = (list) => dispatchOptions(list, dispatch)
  const { goodTypes, truckTypes, adrTypes } = options || {}
  const formatTypes = (typeList) => typeList?.map((type) => ({ value: type?.key, label: type?.value }))
  const fetchTypes = async () => {
    if (fetchingTypes || options?.truckTypes?.length) {
      return
    }
    setLoading(true)
    fetchingTypes = true
    // App initialization starts with this API
    const response = await freightApiGet('/types')
    if (response) {
      const _options = {
        ...options,
        goodTypes: formatTypes(response?.goodTypes),
        truckTypes: formatTypes(response?.truckTypes),
        adrTypes: formatTypes(response?.adrTypes)
      }
      setOptions(_options)
      if (typeof window !== 'undefined') {
        sessionStorage.setItem('options', JSON.stringify(_options))
      }
    }
    setLoading(false)
    fetchingTypes = false
  }

  const companyTypes = [
    { label: t('shipper'), value: 'shipper' },
    { label: t('carrier'), value: 'carrier' },
    { label: t('ff'), value: 'ff' }
  ]

  const specialRequirementOptions = [
    { label: t('two_drivers'), value: 'two_drivers', withLabel: true },
    { label: t('temp_control'), value: 'temp_control' },
    { label: t('adr'), value: 'adr' }
  ]

  const specialRequirementOptionsLong = [
    { label: t('two_drivers'), value: 'isTwoDrivers', withLabel: true },
    { label: t('temp_control'), value: 'isTemperatureControlled' },
    { label: t('adr'), value: 'isAdr' },
    { label: t('high_value_load'), value: 'isValuable' },
    { label: t('pallets_exchange'), value: 'isPalletsExchange' }
  ]

  const timeIntervalItems = [
    { value: 'day', label: t('common:day') },
    { value: 'week', label: t('common:week') },
    { value: 'month', label: t('common:month') },
    { value: 'year', label: t('common:year') },
    { value: 'total', label: t('common:total') }
  ]

  const documentTypes = {
    1: { title: t('business_license'), expiration: false },
    2: { title: t('transport_company_license'), expiration: true },
    3: { title: t('insurance_policy_receipt'), expiration: true },
    4: { title: t('insurance_proof'), expiration: true },
    5: { title: t('truck_insurance_proof'), expiration: true },
    6: { title: t('social_security_certificate'), expiration: false },
    7: { title: t('operator_license'), expiration: false },
    100: { title: t('other'), expiration: false }
  }

  const documentTypeOptions = Object.entries(documentTypes).map(([key, value]) => ({
    value: Number(key),
    label: value.title
  }))

  const subscriptionTypes = [
    { value: 'trial', label: t('trial') },
    { value: 'gift', label: t('gift') },
    { value: 'paid', label: t('paid') }
  ]

  const freightTypes = [
    { label: 'FTL', value: 'FTL', info: t('table:full_truck_load') },
    { label: 'LTL', value: 'LTL', info: t('table:less_than_truck_load') }
  ]

  const truckServiceFreightTypes = [
    { label: 'FTL', value: ShipmentType.Ftl, info: t('table:full_truck_load') },
    { label: 'LTL', value: ShipmentType.Ltl, info: t('table:less_than_truck_load') }
  ]

  const translatedGoodTypes = useMemo(() => goodTypes?.map((type) => ({ ...type, label: t(`options:${type?.label}`) })), [goodTypes])

  const translatedTruckTypes = useMemo(() => truckTypes?.map((type) => ({ ...type, label: t(`options:${type?.label}`) })), [truckTypes])

  const translatedTruckTypesObject = useMemo(() => truckTypes?.reduce((acc, current) => {
    acc[current.value] = t(`options:${current?.label}`)
    return acc
  }, {}), [truckTypes])

  const translatedAdrTypes = useMemo(() => adrTypes?.map((type) => ({ ...type, label: t(`options:${type?.label}`) })), [adrTypes])

  useEffect(() => {
    fetchTypes()
  }, [])

  return {
    companyTypes,
    documentTypes,
    documentTypeOptions,
    subscriptionTypes,
    goodTypes,
    adrTypes,
    truckTypes,
    loading,
    freightTypes,
    specialRequirementOptions,
    specialRequirementOptionsLong,
    truckServiceFreightTypes,
    timeIntervalItems,
    translatedGoodTypes,
    translatedAdrTypes,
    translatedTruckTypes,
    translatedTruckTypesObject
  }
}

export default useOptions
