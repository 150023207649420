import React, { useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import useTranslation from 'next-translate/useTranslation'
import * as Sentry from '@sentry/nextjs'
import 'react-toastify/dist/ReactToastify.css'
import Router from 'next/router'

function Error500PageView({ error, resetError }) {
  const { t } = useTranslation()

  const redirectToHome = () => Router.replace('/')

  const handleReport = () => {
    Sentry.captureMessage(`Error manual reporting: ${error}`)
    toast.info(
      <p>
        {t('errors:thanks_for_reporting')}
        {' '}
        <br />
        {t('errors:solve_problem')}
      </p>
    )
    setTimeout(() => {
      redirectToHome()
    }, 250)
  }

  useEffect(() => {
    if (resetError) {
      return () => resetError() // clear error on page page
    }
    return () => null
  }, [error])

  return (
    <div className="flex flex-col flex-1 items-center justify-center p-16">
      <div className="max-w-512  text-center">
        <ToastContainer limit={5} theme="colored" newestOnTop closeOnClick />
        <div className="bold mb-0 mt-20">
          {t('errors:somethingWentWrong')}
        </div>
        <div
          style={{
            marginTop: 20,
            marginBottom: 20
          }}
          className="text-15"
        >
          {t('errors:looksLikeWeHaveInternalIssue')}
        </div>
        <button
          type="button"
          onClick={handleReport}
          className="cursor-pointer btn standard-btn pt-10 pb-10 mb-10 mr-8"
        >
          {t('errors:report_and_redirect')}
        </button>
      </div>
    </div>
  )
}

export default Error500PageView
