import React, { useEffect, useState } from 'react'
import { ModalType } from 'mp-common/types/ModalType'
import { AnimatePresence, motion } from 'framer-motion'
import { GHref } from 'mp-common/components/form/elements'

const Modal = (props : ModalType) => {
  const {
    id,
    isOpen,
    children,
    close,
    size,
    important,
    extraClass = '',
    style = {},
    extraStyle = {}
  } = props
  const [isListenerMounted, setIsListenerMounted] = useState(false)

  const escFunction = (event) => {
    if (event.key === 'Escape' && close) {
      close()
    }
  }

  useEffect(() => {
    if (!isListenerMounted && isOpen) {
      document.removeEventListener('keydown', escFunction)
      document.addEventListener('keydown', escFunction, false)
    }
    return () => {
      document.removeEventListener('keydown', escFunction)
      setIsListenerMounted(false)
    }
  }, [isOpen])

  return (
    <AnimatePresence>
      {isOpen ? (
        <motion.div
          key="modal-outer"
          id={id}
          className="blocker current"
          style={{ zIndex: important ? 99999 : 2001, ...style }}
          tabIndex={-1}
          onFocus={close}
        >
          <motion.div
            key="modal-inner"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
            transition={{ duration: 0.1, ease: [0.25, 0.54, 0.34, 1] }}
            className={`modal tennders-modal ${extraClass}`}
            style={{ opacity: 1, display: 'inline-flex', width: size || 800, ...extraStyle }}
            tabIndex={0}
            onFocus={(e) => e.stopPropagation()}
          >
            {children}
            {close && <GHref onClick={close} className="close-modal" />}
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default Modal
