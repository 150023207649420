import axios from 'axios'
import { defaultOptions, formatErrors, onFulfilled } from 'mp-structure/libs/api'
import getProperty from 'lodash/get'

const dOptions = {
  params: {}
}

export const freightBaseURL = process?.env?.FREIGHT_API_URL
export const freightInstance = axios.create({ baseURL: freightBaseURL, withCredentials: true })

freightInstance.interceptors.request.use(onFulfilled(false, true))

let isPostRequestInProgress = false
let apiPostURL = ''
let apiPostParams = {}
export const freightApiPost = async (url:string, params:any, options = {}, isFile = false) => {
  try {
    // Check if a request is already in progress
    const _params = params
    if (isPostRequestInProgress && url === apiPostURL && JSON.stringify(_params) === JSON.stringify(apiPostParams)) {
      // If a request is in progress, you can choose to return or throw an error
      return false
    }
    apiPostURL = url
    apiPostParams = params
    // Set the flag to true to indicate that a request is in progress
    isPostRequestInProgress = true
    const results = await freightInstance.post(
      url,
      isFile ? params : { ...params },
      { ...defaultOptions(isFile), ...options }
    )
    isPostRequestInProgress = false
    apiPostURL = ''
    apiPostParams = false
    if (results) {
      return results
    }
    return results
  } catch (ex) {
    isPostRequestInProgress = false
    apiPostURL = ''
    apiPostParams = false
    const error = ex as any
    if ((error.response?.status === 400 || error.response?.status === 500) && error.response?.data?.errors) {
      throw ex
    }
    return formatErrors(ex)
  }
}

let isGetRequestInProgress = false
let apiGetURL = ''
let apiGetParams = {}
// skipProgress is for searchable input
export const freightApiGet = async (url:string, options = dOptions, skipProgress = false) => {
  try {
    // Check if a request is already in progress
    if (!skipProgress && isGetRequestInProgress && url === apiGetURL && JSON.stringify(options) === JSON.stringify(apiGetParams)) {
      // If a request is in progress, you can choose to return or throw an error
      return false
    }
    apiGetURL = url
    isGetRequestInProgress = true
    apiGetParams = options
    if (!options.params) {
      options.params = {}
    }
    const results = await freightInstance.get(url, options)
    apiGetURL = ''
    isGetRequestInProgress = false
    apiGetParams = false
    if (results?.data) {
      return results.data
    }
    return false
  } catch (ex) {
    apiGetURL = ''
    isGetRequestInProgress = false
    apiGetParams = false
    // if we have errors in response that means we want to catch and show
    // these error messages in form
    const error = ex as any
    if (error.response?.status === 400 && error.response?.data?.errors) {
      throw ex
    }
    // @todo do something smart here
    return false
  }
}

let isPutRequestInProgress = false
let apiPutURL = ''
let apiPutParams = {}
export const freightApiPut = async (url:string, params:any = {}, options = {}) => {
  try {
    // Check if a request is already in progress
    if (isPutRequestInProgress && url === apiPutURL && JSON.stringify(params) === JSON.stringify(apiPutParams)) {
      // If a request is in progress, you can choose to return or throw an error
      return false
    }
    apiPutURL = url
    apiPutURL = params
    isPutRequestInProgress = true
    const results = await freightInstance.put(url, { ...params }, options)
    isPutRequestInProgress = false
    apiPutURL = ''
    apiPutParams = false
    if (results.data) {
      return results.data
    }
    return results
  } catch (ex) {
    isPutRequestInProgress = false
    apiPutURL = ''
    apiPutParams = false
    // if we have errors in response that means we want to catch and show
    // these error messages in form
    const error = ex as any
    if (error.response?.status === 400 && error.response?.data?.errors) {
      throw ex
    }
    return formatErrors(ex)
  }
}

freightInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      //  logoutUser()
      //  window.location.href = '/login'
    }
    if (error.response && error.response.status === 400 && error.response.data.violations) {
      const v = error.response.data.violations
      const e:any = {}
      v.forEach((element:any) => {
        if (element.message.indexOf('::') > -1) {
          const parts = element.message.split('::')
          e[parts[0].trim()] = parts[1].trim()
        } else if (element.propertyPath.indexOf('.') > -1) {
          const parts = element.propertyPath.split('.')
          if (!e[parts[0].trim()]) {
            e[parts[0].trim()] = {}
          }
          e[parts[0].trim()][parts[1].trim()] = element.message
        } else {
          e[element.propertyPath] = element.message
        }
      })
      return { errors: e }
    }
    if (error && getProperty(error, 'response.data.detail')) {
      return Promise.reject(error)
    }
    if (error.response?.status === 400 && error.response?.data?.errors) {
      return { errors: error.response?.data?.errors }
    }
    const _err = getProperty(error, 'response.data.error')
    if (_err) {
      return Promise.reject(getProperty(error, 'response.data'))
    }
    return Promise.reject(error)
  }
)
