import React, { useEffect, useMemo, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { FileInput, SelectInput, SingleDatePicker } from 'mp-common/components/form/inputs'
import { InputLabeledContainer } from 'mp-common/components/form/containers'
import { GButton, GHref, GImage } from 'mp-common/components/form/elements'
import useForm from 'mp-common/components/form/hooks/useForm'
import Modal from 'mp-common/components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { StateType } from 'mp-common/types/StateType'
import { dispatchAddDocument } from 'mp-common/stores/modal/action'
import { Dispatch } from 'redux'
import axios from 'axios'
import process from 'process'
import { errorsArrayToObject } from 'mp-structure/libs/utils'
import { useRouter } from 'next/router'
import moment from 'moment'
import { getToken } from 'mp-structure/libs/api'

export type UploadedDocumentType = {
  title: string,
  expireDate?: Date,
  id: string | number,
  documentFile?: any,
  type?: any
}

const initialForm: UploadedDocumentType = {
  title: '',
  id: ''
}

const OPTIONAL_TYPE = 100

const AddDocumentModal = () => {
  const { t } = useTranslation('document')
  const dispatch: Dispatch<any> = useDispatch()
  const modalProps = useSelector((state:StateType) => state.modal.addDocument)
  const {
    handleSubmit,
    addedTypes,
    url,
    withCredentials,
    documentTypeOptions
  } = modalProps || {}
  const { query } = useRouter()
  const [submitting, setSubmitting] = useState(false)
  const {
    form,
    errors,
    setErrors,
    onChange,
    setForm
  } = useForm<UploadedDocumentType>({ initialForm })
  const docTypes = useMemo(() => documentTypeOptions?.reduce((acc, obj) => {
    if (!addedTypes?.has(obj.type) || obj.type === OPTIONAL_TYPE) {
      acc.push({
        label: obj?.title,
        value: obj?.type
      })
    }

    return acc
  }, []), [documentTypeOptions, addedTypes])

  const handleClose = () => {
    setForm(initialForm)
    setErrors({})
    dispatchAddDocument(false, dispatch)
  }

  const removeDocument = () => {
    setForm(initialForm)
  }

  const uploadDocument = async () => {
    setSubmitting(true)
    const token = getToken()

    const formData = new FormData()
    if (!withCredentials) {
      formData.append('activationKey', query?.activationKey?.toString())
    }
    formData.append('title', form?.title)
    formData.append('documentFile', form?.documentFile)
    formData.append('type', form?.type)
    formData.append('expireDate', moment(form?.expireDate).format('YY-MM-DD'))
    try {
      const res = await axios
        .post(process.env.API_URL + url, formData, { headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'multipart/form-data',
          'Accept-Language': 'en-US',
          ...(withCredentials && token && { Authorization: `Bearer ${token}` })
        },
        ...(withCredentials && { withCredentials: true })
        })
      setSubmitting(false)
      if (res?.data?.status === 'document_uploaded') {
        t('document:file_uploaded')
        handleSubmit({
          downloadUrl: res?.data?.downloadUrl,
          expireDate: res?.data?.expireDate,
          title: res?.data?.title,
          type: res?.data?.type,
          id: res?.data?.id
        })
      }
    } catch (e) {
      setSubmitting(false)
      if (e?.response?.data) {
        setErrors(errorsArrayToObject(e.response.data?.details))
      }
    }
  }

  useEffect(() => {
    if (!modalProps) {
      setForm(initialForm)
      setErrors({})
    }
  }, [modalProps])

  return (
    <Modal
      isOpen={!!modalProps}
      close={handleClose}
      extraClass="modal modal-400"
      id="tm-modal-add-new-document"
    >
      <div className="tf-modal-body w-100">
        <h2 className="bold">{t('add_new_document')}</h2>
        <div className="mt-24">
          <div className="mb-16">
            <InputLabeledContainer
              form={form}
              onChange={onChange}
              errors={errors}
              className="mb-16"
              name="type"
              label={t('select_document_type')}
              labelClassName="form-label mb-4"
              renderInput={(props) => (
                <SelectInput
                  className="form-select w-100"
                  items={docTypes}
                  {...props}
                />
              )}
            />
          </div>
          <div className="hr" />
          <div className="d-flex column mb-16">
            <FileInput
              buttonClass="file-upload"
              next={(file) => {
                setForm({
                  ...form,
                  title: file.name,
                  documentFile: file
                })
              }}
              url="/documents"
              className="file-upload__label pt-8 pb-8 w-100 mb-16"
              label={t('document:upload_document')}
              icon="icon-document.svg"
            />
            <InputLabeledContainer
              form={form}
              onChange={onChange}
              errors={errors}
              className="mb-16"
              name="expireDate"
              label={t('expiry_date')}
              labelClassName="form-label mb-4"
              renderInput={(props) => (
                <SingleDatePicker {...props} />
              )}
            />

          </div>
          {form?.title && (
            <div className="d-flex align-center text-12 mt-8 mb-24">
              <div>{form?.title}</div>
              <GHref onClick={removeDocument} className="ml-10 tm-link opacity-50" title="Delete">
                <GImage src="icon-trash.svg" alt="" width="16" />
              </GHref>
            </div>
          )}
          <div>
            <GButton
              className="tm-button-filled color-brown w-100"
              label={t('common:save')}
              submitting={submitting}
              onClick={uploadDocument}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddDocumentModal
