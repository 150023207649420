import { Ability } from './AbilityTypes'

export enum ShipmentType {
  Ftl = 1,
  Ltl = 2
}

export interface TruckListCompanyResponse {
  id: string;
  name: string;
  countryCode ?: string;
  city ?: string;
  logoThumbnail ?: string
}

export interface TruckListTruckProperties {
  requiresTwoDrivers: boolean;
  adr?: boolean;
  shipmentType: ShipmentType;
  temperatureControl?: string;
  weight: number;
  width: number;
  length: number;
  height: number;
  volume?: string;
}
export interface TruckListItem {
  id: string;
  journeyStopId: string;
  truckId: string;
  mapsLink: string;
  totalStopsCount: number;
  totalDistance: number;
  firstPickup: JourneyLocationResponse;
  lastDelivery?: JourneyLocationResponse;
  truckTypeName: string;
  companyName: string;
  isRead: boolean;
  company: TruckListCompanyResponse;
  truckProperties: TruckListTruckProperties;
  currentPrice?: number;
  abilities: Ability[]
}

export interface DateResponse{
  shortFormat: string;
  longFormat: string;
  dateRange: string[]
}
export interface JourneyLocationResponse {
  id: string;
  type: JourneyLocationType;
  allowAnyLocation: boolean;
  location?: LocationResponse;
  availableDate?: DateResponse
  firstDate?: string
  lastDate?: string
}

export interface LocationResponse {
  id: string;
  latitude: number;
  longitude: number;
  address?: string;
  countryCode?: string;
  postalCode?: string;
  flag: string;
  label ?: string
}

export enum JourneyLocationType {
  PickupPoint = 1,
  DeliveryPoint = 2,
  WareHouse = 3
}

export type PaginationResponse = {
  currentPage: number;
  pageSize: number;
  totalPages: number;
  totalCount: number;
}

export type MetaResponse = {
  pagination: PaginationResponse;
}

export type TruckListResponse = {
  data: {
    items: TruckListItem[];
    meta: MetaResponse;
  }
}
