import { useCallback, useState } from 'react'
import { locationInstance } from 'mp-structure/libs/api'
import useSession from 'mp-auth/hooks/useSession'

interface LocationResponse {
  countryCode: string
  postalCode: string
  cityName: string
  areaName: string
  latitude: number
  longitude: number
}

function debounce(fn: any, delay = 250) {
  let timeout: string | number | NodeJS.Timeout

  return (...args: any) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

const useLocationSearch = () => {
  const { user } = useSession()
  const [locations, setLocations] = useState<LocationResponse[]>([])
  const fetchData = async (inputValue: string) => {
    if (inputValue.length < 2) {
      return []
    }
    const { data } = await locationInstance.post('api/Search', {
      searchText: inputValue,
      searchOrigin: user.user
    })
    setLocations(data.data)
    return data.data
  }

  const fetchDataDebounced = useCallback(
    debounce((inputValue: string, callback: (options: any) => void) => {
      fetchData(inputValue).then(options => callback(options))
    }, 325),
    []
  )

  return { fetchData: fetchDataDebounced, locations }
}
export default useLocationSearch
