import { HTMLProps } from 'react'

type RangeInputProps = Omit<HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'value' | 'form'> & {
  onSet?: (value: any) => void,
  value?: string,
  error?: string,
  name?: string,
  defaultValue?: string,
  eClass?: string,
  min?: number,
  max?: number,
  step?: number
}

const RangeInput = (props: RangeInputProps) => {
  const {
    onSet,
    disabled,
    readOnly,
    className,
    value,
    error,
    defaultValue = 0,
    name,
    eClass,
    min = 200,
    max = 1000,
    step = 100
  } = props
  const val = value || defaultValue

  const onChange = (e: any) => {
    if (readOnly || disabled) {
      return
    }
    if (onSet) {
      onSet(e.target?.value)
    }
  }

  return (
    <div className={`tm-range-slider-container${eClass ? ` ${eClass}` : ''}`}>
      <input
        className={`tm-range-slider${className ? ` ${className}` : ''}${error ? ' error' : ''}`}
        type="range"
        min={min}
        max={max}
        step={step}
        value={val}
        disabled={disabled || readOnly}
        readOnly={readOnly}
        onChange={onChange}
        name={name}
      />
      <p className="text-14 fw-700">
        <span className="tm-range-slide-value">
          {val || min}
        </span>
      </p>
    </div>
  )
}

export default RangeInput
