import { useMemo, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Modal from 'mp-common/components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { StateType } from 'mp-common/types/StateType'
import { dispatchCompanyTypeModal } from 'mp-common/stores/modal/action'
import { Dispatch } from 'redux'
import { GHref, GImage } from 'mp-common/components/form/elements'

const CompanyTypeModal = () => {
  const { t } = useTranslation('onboarding')
  const dispatch: Dispatch<any> = useDispatch()
  const modalProps = useSelector((state:StateType) => state.modal.companyType)
  const [submitting, setSubmitting] = useState(false)
  const { onChange } = modalProps || {}

  const rows = useMemo(() => [
    { action: t('add_freight'), shipper: true, carrier: false, ff: true },
    { action: t('see_all_freights'), shipper: false, carrier: true, ff: true },
    { action: t('add_mini_tender'), shipper: true, carrier: false, ff: true },
    { action: t('see_all_tenders'), shipper: false, carrier: true, ff: true },
    { action: t('add_truck'), shipper: false, carrier: true, ff: true },
    { action: t('see_all_trucks'), shipper: true, carrier: false, ff: true }
  ], [])

  const handleClose = () => {
    dispatchCompanyTypeModal(false, dispatch)
  }

  const submit = async () => {
    setSubmitting(true)
    onChange()
    setSubmitting(false)
    handleClose()
  }

  return (
    <Modal
      isOpen={!!modalProps}
      close={handleClose}
      extraClass="modal"
    >
      <div className="tf-modal-body w-100">
        <div className="mp-grid-2-col gap-16">
          <h2 className="bold column-2">{t('company_types_table_title')}</h2>
          <table className="column-2 tm-company-type-table">
            <thead>
              <tr>
                <th>{t('fex_actions')}</th>
                <th>{t('shippers')}</th>
                <th>{t('carriers')}</th>
                <th>{t('ffs')}</th>
              </tr>
            </thead>
            <tbody>
              {rows?.map((row) => (
                <tr key={row.action}>
                  <td>{row.action}</td>
                  <td>{row.shipper && <GImage src="icon-check-form-green.svg" />}</td>
                  <td>{row.carrier && <GImage src="icon-check-form-green.svg" />}</td>
                  <td>{row.ff && <GImage src="icon-check-form-green.svg" />}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="column-2 text-center">
            {t('confirm_company_type')}
          </div>
          <GHref submitting={submitting} onClick={submit} className="tm-button-filled color-brown">
            {t('common:yes')}
          </GHref>
          <GHref onClick={handleClose} className="tm-button-bordered color-brown">
            {t('common:no')}
          </GHref>
        </div>
      </div>
    </Modal>
  )
}

export default CompanyTypeModal
