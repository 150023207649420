import useTranslation from 'next-translate/useTranslation'
import { Dispatch } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { StateType } from 'mp-common/types/StateType'
import { dispatchReminderMessage } from 'mp-common/stores/modal/action'
import { GHref, GImage } from 'mp-common/components/form/elements'
import store from 'store'

const ReminderMessageModal = () => {
  const { t } = useTranslation()
  const dispatch: Dispatch<any> = useDispatch()
  const modalProps = useSelector((state:StateType) => state.modal.reminderMessage)
  const close = () => {
    dispatchReminderMessage(false, dispatch)
    store.set('_msg_shown', 'true')
  }

  return modalProps
    ? (
      <div className="tm-fixed-msg bottom">
        <div>
          {t('document:upload_documents_msg')}
          <GHref href="/company-profile/documents" className="text-color-yellow-100">Upload now!</GHref>
        </div>
        <GHref onClick={close} className="tm-fixed-msg-close-btn" title="Close">
          <GImage src="icon-close.svg" alt="Close" width={20} height={20} />
        </GHref>
      </div>
    ) : null
}

export default ReminderMessageModal
