import { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import Modal from 'mp-common/components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { StateType } from 'mp-common/types/StateType'
import { dispatchTermsAndConditions } from 'mp-common/stores/modal/action'
import { Dispatch } from 'redux'
import { apiGuestGet } from 'mp-structure/libs/api'
import DOMPurify from 'dompurify'
import GLoading from 'mp-common/components/GLoading'
import { GHref } from 'mp-common/components/form/elements'

const TermsAndConditionsModal = () => {
  const dispatch: Dispatch<any> = useDispatch()
  const modalProps = useSelector((state:StateType) => state.modal.termsAndConditions)
  const [terms, setTerms] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const { lang, t } = useTranslation()

  const handleClose = () => {
    if (modalProps?.onClose) {
      modalProps.onClose()
    }
    dispatchTermsAndConditions(false, dispatch)
  }

  const getTemplate = async () => {
    setLoading(true)
    const res = await apiGuestGet(`${modalProps?.url}/${lang?.substring(0, 2)}`)
    setLoading(false)

    if (res?.terms || res?.privacy_policy) {
      setTerms(res.terms || res?.privacy_policy)
    }
  }

  useEffect(() => {
    if (modalProps && lang) {
      getTemplate()
    }
  }, [modalProps, lang, modalProps?.url])

  return (
    <Modal
      isOpen={!!modalProps}
      close={handleClose}
      extraClass="modal"
      id="tm-modal-terms-and-conditions"
    >
      <div className="tf-modal-body w-100">
        <h1 className="text-18 mt-16 mb-16 bold text-center">{modalProps?.title}</h1>
        {loading
          ? <GLoading />
          : terms
            ? (
              <div
                className="tm-white-box tm-preview-wrapper"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(terms) }}
              />
            )
            : (
              <div className="d-flex justify-center m-auto">{t('onboarding:terms_not_found')}</div>
            )}
        <GHref
          className="tm-button-filled color-brown ml-auto mt-16"
          onClick={() => {
            if (modalProps?.onAccept) {
              modalProps?.onAccept()
            }
            dispatchTermsAndConditions(false, dispatch)
          }}
        >
          {t('common:accept')}
        </GHref>
      </div>
    </Modal>
  )
}

export default TermsAndConditionsModal
