import { HTMLProps, memo } from 'react'
import AsyncSelect from 'react-select/async'
import useLocationSearch from 'mp-common/hooks/useLocationSearch'
import { LocationResponse } from 'mp-truck/types/TruckType'
import useTranslation from 'next-translate/useTranslation'

type Props = Omit<HTMLProps<HTMLSelectElement>, 'onChange'> & {
  onSet?: (value ?: LocationResponse) => void,
  onDelete?: () => void,
  customClassName?: string,
  placeholder?: any,
  required?: boolean
  error?: boolean,
  disabled?: boolean,
  readOnly?: boolean,
  isMulti?: any,
  value: LocationResponse
}

const LocationInput = (props: Props) => {
  const {
    onSet,
    customClassName,
    placeholder,
    required,
    error,
    disabled,
    readOnly,
    value,
    isMulti,
    onDelete
  } = props
  const { fetchData } = useLocationSearch()
  const { t } = useTranslation()
  const _placeholder = placeholder || t('dynamicPlaceholders:location_placeholder')

  return (
    <AsyncSelect<LocationResponse>
      loadOptions={fetchData}
      isSearchable={!readOnly}
      components={{
        DropdownIndicator: () => null
      }}
      value={value || null}
      isClearable={!!value?.label}
      className={(
        'country-select-container location-input'
        + (required ? ' required' : '')
        + (error ? ' error' : '')
        + (disabled ? ' disabled' : '')
        + (customClassName ? ' ' + customClassName : '')
      )}
      defaultOptions
      classNamePrefix="country-select"
      placeholder={_placeholder}
      isMulti={isMulti}
      isDisabled={disabled || readOnly}
      getOptionLabel={(item) => (item.label ? item.label : _placeholder)}
      getOptionValue={item => item.label}
      onChange={(e, triggeredAction) => {
        if (triggeredAction.action === 'clear' && onDelete) {
          onDelete()
        } else if (onSet) {
          onSet(e)
        }
      }}
      styles={{
        dropdownIndicator: (provided, state) => ({
          ...provided,
          ...(state.selectProps.menuIsOpen && { transform: 'rotate(180deg)' })
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none'
        }),
        loadingIndicator: (provided) => ({
          ...provided,
          visibility: 'hidden'
        })
      }}
    />
  )
}
export default memo(LocationInput)
