import { ceil } from 'lodash'
import { CSSProperties, memo } from 'react'

type Props = {
  size ?: number,
  color ?: string,
  zIndex ?: number,
  customLoadingClassName ?: string
  customStyle ?: CSSProperties,
  loaderStyle ?: CSSProperties,
}

const GLoading = ({ size = 14, color = 'black', customLoadingClassName, zIndex, customStyle, loaderStyle } : Props) => (
  <div
    className={'pos-rel w-100 m-5 ' + (customLoadingClassName || '')}
    style={{ minHeight: ceil(size * 1.2), zIndex: zIndex || 1000, ...(customStyle) }}
  >
    <div style={{ fontSize: (size), color, ...(loaderStyle) }} className="mp-loading">
      <i aria-hidden className="fa fa-spinner fa-spin" style={{ fontSize: (size || 14) }} />
    </div>
  </div>
)

export default memo(GLoading)
