// eslint-disable-next-line no-use-before-define
import React from 'react'
import * as Sentry from '@sentry/nextjs'
import Error500Page from 'mp-structure/layouts/views/error/Error500PageView'

type Props = {
  children: React.ReactNode | React.ReactNode[],
}

type State = {
  hasError: boolean,
  error ?: any
}

class Error500Boundary extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render() {
    const { hasError, error } = this.state
    const { children } = this.props
    if (hasError) {
      return <Error500Page error={error} resetError={undefined} />
    }

    return children
  }
}

export default Error500Boundary
