import AddDocumentModal from 'mp-common/components/modals/AddDocumentModal'
import Toast from 'mp-structure/layouts/views/toast/Toast'
import TermsAndConditionsModal from 'mp-common/components/modals/TermsAndConditionsModal'
import ReminderMessageModal from 'mp-common/components/modals/ReminderMessageModal'
import ConfirmActionModal from 'mp-common/components/modals/ConfirmActionModal'
import CompanyTypeModal from 'mp-common/components/modals/CompanyTypeModal'
import SaveFilterModal from 'mp-common/components/modals/SaveFilterModal'

const LayoutModals = () => (
  <>
    <AddDocumentModal />
    <TermsAndConditionsModal />
    <ConfirmActionModal />
    <Toast />
    <ReminderMessageModal />
    <CompanyTypeModal />
    <SaveFilterModal />
  </>
)

export default LayoutModals
