import { useEffect, useState } from 'react'
import Head from 'next/head'
import { MetaType } from '../../types/MetaType'

type Props = {
  meta?: MetaType
}

const getFaviconPath = (isDarkMode = false) => `/favicon-${isDarkMode ? 'light' : 'dark'}.svg?v=2`

function Meta({ meta } : Props) {
  const [faviconHref, setFaviconHref] = useState('/favicon-light.svg?v=2')

  useEffect(() => {
    // Get current color scheme.
    const matcher = window.matchMedia('(prefers-color-scheme: dark)')
    // Set favicon initially.
    setFaviconHref(getFaviconPath(matcher.matches))
    // Change favicon if the color scheme changes.
    matcher.onchange = () => setFaviconHref(getFaviconPath(matcher.matches))
  }, [faviconHref])

  return (
    <Head>
      <link rel="icon" href={faviconHref} />
      <meta charSet="utf-8" />
      {meta && meta.desc ? (
        <meta name="description" content={meta.desc} />
      ) : null}
      {meta && meta.keywords ? (
        <meta name="keywords" content={meta.keywords} />
      ) : null}
      {meta && meta.canonical ? (
        <link rel="canonical" href={meta.canonical} />
      ) : null}
      {meta && meta.noIndex ? (
        <meta name="robots" content="noindex,nofollow" />
      ) : null}
      <meta property="og:type" content="website" />
      {meta && meta.og && meta.og.title ? (
        <meta property="og:title" content={meta.og.title} />
      ) : null}
      {meta && meta.og && meta.og.desc ? (
        <meta property="og:description" content={meta.og.desc} />
      ) : null}
      {meta && meta.og && meta.og.image ? (
        <meta property="og:image" content={meta.og.image} />
      ) : null}
      {meta && meta.canonical ? (
        <meta property="og:url" content={meta.canonical} />
      ) : null}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="noindex, nofollow" />
      <link rel="apple-touch-icon" sizes="180x180" href="/img/apple-touch-icon.png" />
      <title>{meta?.title ? meta?.title : 'Tennders'}</title>
    </Head>
  )
}
export default Meta
