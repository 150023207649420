import { create } from 'zustand'

const usePopupStore = create((set) => ({
  warning: null,
  success: null,
  confirm: null,
  confirming: null,
  checkOffer: null,
  information: null,
  show: (type, message) => set((state) => ({ ...state, [type]: message }))
}))

export default usePopupStore
