import useTranslation from 'next-translate/useTranslation'
import Modal from 'mp-common/components/Modal'
import { Dispatch } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { StateType } from 'mp-common/types/StateType'
import { dispatchSaveFilterModal } from 'mp-common/stores/modal/action'
import { useEffect, useMemo, useState } from 'react'
import { CheckBoxMultiple, RadioGroup, TextInput } from 'mp-common/components/form/inputs'
import { GHref } from 'mp-common/components/form/elements'
import HorizontalInputLabelContainer from 'mp-common/components/form/containers/HorizontalInputLabelContainer'
import LocationInput from 'mp-common/components/form/inputs/LocationInput'
import RangeInput from 'mp-common/components/form/inputs/RangeInput'
import useOptions from 'mp-common/hooks/useOptions'
import SelectInput from 'mp-common/components/form/inputs/SelectInput'
import useForm from 'mp-common/components/form/hooks/useForm'
import { isNaN } from 'lodash'

const SaveFilterModal = () => {
  const { t } = useTranslation('table')
  const dispatch: Dispatch<any> = useDispatch()
  const modalProps = useSelector((state:StateType) => state.modal.saveFilterModal)
  const { localForm, saveFilter } = modalProps || {}
  const { specialRequirements } = localForm || {}
  const [filterName, setFilterName] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { form, onChange, setForm } = useForm({})
  const { specialRequirementOptionsLong, translatedTruckTypes, freightTypes } = useOptions()

  const savableFields = useMemo(() => {
    const _savableFields: any = { ...form }
    delete _savableFields.loadingDate
    delete _savableFields.unloadingDate
    delete _savableFields.publicationDate
    delete _savableFields.page
    delete _savableFields.perPage
    if (_savableFields.radiusTo === 200) {
      delete _savableFields.radiusTo
    }
    if (_savableFields.radiusFrom === 200) {
      delete _savableFields.radiusFrom
    }

    return _savableFields
  }, [form])

  const disableSubmit = useMemo(() => Object.values(savableFields).every(value => {
    if (typeof value === 'string') {
      return value.trim().length === 0 // Empty string
    }
    if (typeof value === 'number') {
      return isNaN(value) || value === 0 // 0 or NaN
    }
    if (Array.isArray(value)) {
      return value.length === 0 // Empty array
    }
    if (typeof value === 'object' && value !== null) {
      return Object.keys(value as object).length === 0 // Empty object
    }
    return value === null || value === undefined // Null or undefined
  }), [savableFields])

  const handleClose = () => {
    dispatchSaveFilterModal(false, dispatch)
    setFilterName('')
  }

  const onSave = () => {
    if (saveFilter) {
      setSubmitting(true)
      saveFilter({ form: savableFields, name: filterName })
      setSubmitting(false)
      handleClose()
    }
  }

  useEffect(() => {
    if (localForm) {
      setForm({ ...localForm })
    }
  }, [localForm])

  return (
    <Modal isOpen={!!modalProps} close={handleClose}>
      <div className="d-flex column gap-8 w-100">
        <h2 className="bold column-2">{t('save_filter')}</h2>
        <div className="mp-grid-1-col gap-8">
          <HorizontalInputLabelContainer
            form={{ filterName }}
            name="filterName"
            required
            label={t('table:filter_name')}
            onChange={(key, value) => setFilterName(value)}
            renderInput={(props) => (
              <TextInput {...props} maxLength={25} />
            )}
          />
          {localForm?.companyName && (
            <HorizontalInputLabelContainer
              className="mb-16"
              form={form}
              name="companyName"
              onChange={onChange}
              label={t('table:company_name')}
              labelClassName="form-label mb-4"
              renderInput={(props) => (
                <div className="pos-rel">
                  <TextInput {...props} />
                </div>
              )}
            />
          )}
          {localForm?.contactName && (
            <HorizontalInputLabelContainer
              className="mb-16"
              form={form}
              name="contactName"
              onChange={onChange}
              label={t('table:contact_person')}
              labelClassName="form-label mb-4"
              renderInput={(props) => (
                <div className="pos-rel">
                  <TextInput {...props} />
                </div>
              )}
            />
          )}
          {localForm?.locationFrom && (
            <HorizontalInputLabelContainer
              className="mb-16"
              form={form}
              name="locationFrom"
              onChange={onChange}
              label={t('table:loading_location')}
              labelClassName="form-label mb-4"
              renderInput={(props) => (
                <LocationInput {...props} value={form?.locationFrom} />
              )}
            />
          )}
          {localForm?.locationsFrom && localForm?.locationsFrom?.length > 0 && (
            <HorizontalInputLabelContainer
              className="mb-16"
              form={form}
              name="locationsFrom"
              onChange={onChange}
              label={t('table:loading_location')}
              labelClassName="form-label mb-4"
              renderInput={(props) => (
                <LocationInput {...props} isMulti value={form?.locationsFrom} />
              )}
            />
          )}
          {localForm?.locationFrom && localForm?.radiusFrom && (
            <HorizontalInputLabelContainer
              className="mb-16"
              form={form}
              name="radiusFrom"
              onChange={onChange}
              label={t('table:radius_range')}
              labelClassName="form-label mb-4"
              renderInput={(props) => (
                <RangeInput
                  min={0}
                  defaultValue="200"
                  {...props}
                />
              )}
            />
          )}
          {localForm?.locationTo && (
            <HorizontalInputLabelContainer
              className="mb-16"
              form={form}
              name="locationTo"
              onChange={onChange}
              label={t('table:unloading_location')}
              labelClassName="form-label mb-4"
              renderInput={(props) => (
                <div className="pos-rel">
                  <LocationInput {...props} value={form?.locationTo} />
                </div>
              )}
            />
          )}
          {localForm?.locationsTo && localForm?.locationsTo?.length > 0 && (
            <HorizontalInputLabelContainer
              className="mb-16"
              form={form}
              name="locationsTo"
              onChange={onChange}
              label={t('table:unloading_location')}
              labelClassName="form-label mb-4"
              renderInput={(props) => (
                <LocationInput {...props} isMulti value={form?.locationsTo} />
              )}
            />
          )}
          {localForm?.locationTo && localForm?.radiusTo && (
            <HorizontalInputLabelContainer
              className="mb-16"
              form={form}
              name="radiusTo"
              onChange={onChange}
              label={t('table:radius_range')}
              labelClassName="form-label mb-4"
              renderInput={(props) => (
                <RangeInput
                  min={0}
                  defaultValue="200"
                  {...props}
                />
              )}
            />
          )}
          {localForm?.truckType && (
            <HorizontalInputLabelContainer
              className="mb-16"
              form={form}
              name="truckType"
              onChange={onChange}
              label={t('table:truck_type')}
              labelClassName="form-label mb-4"
              renderInput={(props) => (
                <SelectInput
                  className="form-select w-100"
                  items={translatedTruckTypes}
                  {...props}
                />
              )}
            />
          )}
          {localForm?.freightType && (
            <HorizontalInputLabelContainer
              className="mb-16"
              form={form}
              name="freightType"
              onChange={onChange}
              label={t('table:freight_type')}
              labelClassName="form-label mb-4"
              renderInput={(props) => (
                <RadioGroup
                  items={freightTypes}
                  {...props}
                />
              )}
            />
          )}
          {specialRequirements && (
            <HorizontalInputLabelContainer
              className="mb-16"
              form={form}
              name="specialRequirements"
              onChange={onChange}
              label={t('table:special_requirements')}
              labelClassName="form-label mb-4"
              renderInput={(props) => (
                <CheckBoxMultiple
                  items={specialRequirementOptionsLong}
                  flow="column"
                  {...props}
                />
              )}
            />
          )}
        </div>
        <div>
          <GHref
            className="tm-button-filled color-brown w-fit-content ml-auto"
            onClick={onSave}
            submitting={submitting}
            disabled={!filterName || disableSubmit}
          >
            {t('common:save')}
          </GHref>
        </div>
      </div>
    </Modal>
  )
}

export default SaveFilterModal
